import React from 'react';

import {getPhonemeDeletion} from '../../constants/tests.js';

import AdminTestPanel from '../../components/AdminTestPanel';
import strings from '../../strings';
import AdminReadyButton from '../../components/AdminReadyButton';

class PhonemeDeletionTestAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			stopOnConsecutiveErrors: false,
			enableTimers: false,
		});
	}

	getTestName = () => {
		const testName = this.props.testKeyType.includes('child') ? strings.pd_child_testname:strings.pd_testname;
		return testName;
	};

	renderIntro = () => {
		const {currentStep} = this.state;
		const introSteps = getPhonemeDeletion().getIntroSteps();
		const isPracticeTask =
			currentStep > 0 && currentStep < introSteps.length - 1;
		return (
			<>
				<p>{strings.pd_admin1}</p>
				{currentStep === 0 && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStep}
					/>
				)}
				{isPracticeTask && (
					<>
						{strings.formatString(
							strings.taskNum,
							introSteps[currentStep].task
						)}
						<br />
						{strings.formatString(
							strings.expectedAnswer,
							<b>{introSteps[currentStep].expectedAnswer}</b>
						)}
						<br />
						<button onClick={this.props.nextStep}>
							{strings.correctAnswerGiven}
						</button>
					</>
				)}
				{currentStep === 7 && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStage}
					/>
				)}
			</>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		return (
			<div>
				<p>
					{strings.pd_admin2}
					<br />
					{strings.hotkeyDescription}
					<br />
					{strings.hotkeyCorrect} <br /> {strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						getPhonemeDeletion().getAnswers().length
					)}
				</p>
				<p>
					{strings.formatString(
						strings.taskNum,
						getPhonemeDeletion().getSteps()[currentStep]
					)}
					<br />
					{strings.formatString(
						strings.expectedAnswer,
						<b>{getPhonemeDeletion().getAnswers()[currentStep]}</b>
					)}
				</p>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		const testName = this.props.testKeyType.includes('child') ? strings.pd_child_testname:strings.pd_testname;
		
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						testName
					)}
				</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
}

export default PhonemeDeletionTestAdmin;
